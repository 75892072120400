.bpChatbotContainer {
  position: relative;
  width: min(764px, 50vw);
  height: 80px;
}

.bpSearchContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: min(764px, 50vw);
  display: flex;
  flex-direction: column;
}

.bpMessageListMarqueeContainer {
  visibility: hidden !important;
}

.bpContainer {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(45deg, #0045b7 0%, #0650be 20%, #3ebeff 80%, #3ebeff 100%) border-box !important;
  border: 2px solid transparent !important;
  border-radius: 26px !important;

  box-shadow: 0px 6px 25px 0px rgba(10, 36, 86, 0.15) !important;
  height: 630px !important;
  width: 100% !important;
  transform: translateY(-77px) !important;
  z-index: 3 !important;
}

.bpMessageContainer[data-direction='incoming'] .bpMessageBlocksBubble {
  background-color: #f4f4f5 !important;
  border-radius: 10px !important;
  padding: 10px 16px !important;
}

.bpMessageContainer[data-direction='outgoing'] .bpMessageBlocksBubble {
  background-color: #1d6bfc !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  padding: 10px 16px !important;
}

.bpMessageBlocksTextText {
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.bpComposerContainer {
  margin: 0px !important;
  background-color: #ffffff !important;
  border-color: #e9eaeb !important;
  border-radius: 0px !important;
}

.bpComposerInput {
  margin: 20px 16px !important;
  background-color: #ffffff !important;
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.bpComposerVoiceButtonContainer {
  aspect-ratio: 1 !important;
  margin: 8px 16px !important;
}

.bpComposerButtonContainer {
  aspect-ratio: 1 !important;
  margin: 8px 16px !important;
}

.bpComposerButtonIcon {
  background-color: #1d6bfc !important;
}

.bpChatbotSearchInput.MuiTextField-root {
  width: 100%;
  height: 54px;
}

.bpChatbotSearchInput.MuiTextField-root .MuiOutlinedInput-notchedOutline {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(10deg, #0045b7 0%, #0650be 20%, #3ebeff 80%, #3ebeff 100%) border-box !important;
  border: 2px solid transparent !important;
  border-radius: 35px;
}

.bpChatbotSearchInput.MuiTextField-root .MuiInputAdornment-root {
  z-index: 2;
}

.bpChatbotSearchInput.MuiTextField-root input {
  background-color: transparent;
  z-index: 2;
  padding: 0px 12px 0px 0px;
  color: #1b273c;
}

.bpChatbotSearchInput.MuiTextField-root .MuiOutlinedInput-root {
  z-index: 2;
  padding: 16px 12px;
}

.chatbotCollapsed {
  max-height: 0px;
  transition: max-height 0.25s ease-out;
}

.chatbotExpanded {
  max-height: 580px;
  transition: max-height 0.25s ease-in;
}

.chatbotCollapsed .bpContainer {
  max-height: 52px;
  transition: max-height 0.25s ease-out, visibility 0.25s ease-out;
  visibility: hidden !important;
}

.chatbotExpanded .bpContainer {
  max-height: 630px;
  transition: max-height 0.25s ease-in;
}

.bpChatbotHeader {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 3;
}

.bpChatbotHeaderCollapsed {
  height: 0px;
  max-height: 0px;
  transition: all 0.15s ease-out;
  padding: 0px 22px;
}

.bpChatbotHeaderExpanded {
  height: 50px;
  max-height: 50px;
  transition: all 0.15s ease-in;

  border-radius: 27px 27px 0px 0px;
  border-bottom: 1px solid #e9eaeb;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 22px;
}

.bpChatbotHeaderSearchButton {
  min-width: auto !important;
  min-height: auto !important;
  padding: 0px !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #1d6bfc !important;
  z-index: 4 !important;
  position: absolute !important;
  top: 13px !important;
  right: 20px !important;
}

.bpChatbotHeaderSearchButton:hover {
  background-color: #154fba !important;
}

.bpChatbotHeaderButtonContainer {
  width: 100%;
  height: 50px;
  max-height: 50px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bpChatbotHeaderButton {
  min-width: auto !important;
  min-height: auto !important;
  padding: 5px !important;
  width: 30px !important;
  height: 30px !important;
  border: 1px solid #e9eaeb !important;
  border-radius: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #ffffff !important;
  z-index: 4 !important;
  position: absolute !important;
}

.bpChatbotHeaderButton:hover {
  background-color: #f4f4f5 !important;
}

.bpMessageBlocksTextPre {
  white-space: normal !important;
}

.bpMessageBlocksDropdownContentContainer {
  z-index: 10 !important;
}

.bpMessageBlocksTextOrderedList .bpMessageBlocksTextListItem .bpMessageBlocksTextText {
  display: inline !important;
}

.bpMessageAvatarContainer {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50px !important;
}

.showSubmitCase .bpChatbotContainer {
  width: min(calc(764px + 120px + 16px), 50vw);
}

.showSubmitCase .bpSearchContainer {
  width: min(calc(764px + 120px + 16px), 50vw);
}

.showSubmitCase .bpContainer {
  width: calc(100% - (120px + 16px)) !important;
}
